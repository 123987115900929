/* MegaMenu */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {
  var MegaMenuItem = function (menuItem) {
    this.menuItem = $(menuItem);
    this.enterDelayId = null;
    this.exitDelayId = null;
    this.byClick = false;
    this.menuItem.bind('mouseenter', this.onEnter.bind(this));
    this.menuItem.bind('mouseleave', this.onExit.bind(this));
    this.menuItem.bind('click', this.onClick.bind(this));
  };
  MegaMenuItem.prototype = {
    stopEnter: function () {
      if (this.enterDelayId) {
        clearTimeout(this.enterDelayId);
        this.enterDelayId = null;
      }
    },
    stopExit: function () {
      if (this.exitDelayId) {
        clearTimeout(this.exitDelayId);
        this.exitDelayId = null;
      }
    },
    onClick: function (event) {
      this.stopEnter();
      this.stopExit();
      if (MegaMenu.isShowing()) {
        if (MegaMenu.shownFor() > 500) {
          this.hide();
        }
      }
      else {
        this.show(true);
      }
    },
    onEnter: function (event) {
      this.stopExit();
      if (MegaMenu.isShowing()) {
        var byClick = MegaMenu.getShowing().byClick;
        MegaMenu.hide();
        this.show(byClick);
      }
      else {
        this.enterDelayId = setTimeout(this.show.bind(this), 500);
      }
    },
    onExit: function (event) {
      this.stopEnter();
      this.exitDelayId = setTimeout(this.hide.bind(this), this.byClick ? 2000 : 500);
    },
    show: function (byClick) {
      this.byClick = byClick;
      this.menuItem.addClass("hovering");
      MegaMenu.onShow(this);
    },
    hide: function () {
      this.stopExit();
      this.menuItem.removeClass("hovering");
      MegaMenu.onHide(this);
    }
  };
  var MegaMenu = {
    showingMenuItem: null,
    load: function () {
      $("li.mega").each(function () {
        new MegaMenuItem(this);
      });
      $(window).bind("click", function () {
        if (MegaMenu.isShowing() && MegaMenu.getShowing().exitDelayId != null) {
          MegaMenu.hide();
        }
      });
    },
    hide: function () {
      if (MegaMenu.showingMenuItem != null) {
        MegaMenu.showingMenuItem.hide();
      }
    },
    onShow: function (menuItem) {
      MegaMenu.showingMenuItem = menuItem;
      MegaMenu.shownAt = new Date().getTime();
    },
    shownFor: function () {
      if (MegaMenu.showingMenuItem != null) {
        return new Date().getTime() - MegaMenu.shownAt;
      }
      return -1;
    },
    onHide: function (menuItem) {
      if (MegaMenu.showingMenuItem == menuItem) {
        MegaMenu.showingMenuItem = null;
      }
    },
    isShowing: function () {
      return MegaMenu.showingMenuItem != null;
    },
    getShowing: function () {
      return MegaMenu.showingMenuItem;
    }
  };

  $(window).load(MegaMenu.load);

  return MegaMenu;
}));